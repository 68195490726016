
import { createTheme } from "@material-ui/core/styles";

export const primaryColor = "#08080A";
export const whiteColor = "#FFFFFF";
export const secondaryWhiteColor = "#F3F3F3";
export const grayColor = "#333333";
export const secondaryGrayColor = "#666666";

export function Theme(locale, mode) {
    return createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: primaryColor,
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? secondaryWhiteColor : grayColor,
                },
            },
            palette: {
                type: mode,
                primary: { main: primaryColor },
                textPrimary: mode === "light" ? primaryColor : whiteColor,
                borderPrimary: mode === "light" ? primaryColor : whiteColor,
                dark: { main: mode === "light" ? grayColor : secondaryWhiteColor },
                light: { main: mode === "light" ? secondaryWhiteColor : grayColor },
                tabHeaderBackground: mode === "light" ? secondaryWhiteColor : grayColor,
                optionsBackground: mode === "light" ? secondaryWhiteColor : grayColor,
                options: mode === "light" ? secondaryWhiteColor : secondaryGrayColor,
                fontecor: mode === "light" ? primaryColor : whiteColor,
                fancyBackground: mode === "light" ? secondaryWhiteColor : grayColor,
                bordabox: mode === "light" ? secondaryWhiteColor : grayColor,
                newmessagebox: mode === "light" ? secondaryWhiteColor : grayColor,
                inputdigita: mode === "light" ? whiteColor : grayColor,
                contactdrawer: mode === "light" ? whiteColor : grayColor,
                announcements: mode === "light" ? secondaryWhiteColor : grayColor,
                login: mode === "light" ? whiteColor : primaryColor,
                announcementspopover: mode === "light" ? whiteColor : grayColor,
                chatlist: mode === "light" ? secondaryWhiteColor : grayColor,
                boxlist: mode === "light" ? secondaryWhiteColor : grayColor,
                boxchatlist: mode === "light" ? secondaryWhiteColor : grayColor,
                total: mode === "light" ? whiteColor : grayColor,
                messageIcons: mode === "light" ? "grey" : secondaryWhiteColor,
                inputBackground: mode === "light" ? whiteColor : grayColor,
                barraSuperior: mode === "light" ? primaryColor : secondaryGrayColor,
                boxticket: mode === "light" ? secondaryWhiteColor : grayColor,
                campaigntab: mode === "light" ? secondaryWhiteColor : grayColor,
            },
            mode,
        },
        locale
    );
} 
